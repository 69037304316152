import './src/ypa.scss'

(function() {
  var adYpaBsOp={
      adBaseUrl:'//ypa.focusoftime.com',
      maxLoop:6,
      adOpt:{tt:'',u:location.hostname,p:location.protocol.replace(':', '')},
      randomSelect: function(keywordUnits) {
          return keywordUnits[Math.floor(Math.random() * keywordUnits.length)];
      },
      shuffle: function(o) {
          for (var j, x, i = o.length; i;) {
              j = Math.floor(Math.random() * i);
              x = o[--i];
              o[i] = o[j];
              o[j] = x;
          }
          return o;
      },
      loadAd: function(kwlist,selTag,adSty,adMax,adAmo,callback) {
          if (typeof kwlist == 'undefined' || kwlist.length <1 || typeof selTag == null || selTag == '' || $(selTag).length<1) return false;
          if ($.trim(kwlist[0])=='')return false;
          adSty = adSty||1, adMax=adMax||1,adAmo = adAmo||{},adAmo.loopMax = adAmo.loopMax||adYpaBsOp.maxLoop,adAmo.adNum = adAmo.adNum||0,adAmo.loopNum = adAmo.loopNum||0;
          adYpaBsOp.adOpt.tt = decodeURIComponent(kwlist[0]);
          adYpaBsOp.adOpt.ts = new Date().getTime();
          var adBsUrl = adYpaBsOp.adBaseUrl+'/?' + $.param(adYpaBsOp.adOpt);
          adAmo.loopNum++;
          $.ajax({url:adBsUrl,dataType: 'jsonp'}).done(function(data){
              var loopAd = false;
              if (typeof data.ads != 'undefined' && data.ads.length) {
                  var adBsAds = data.ads;
                  for (var index in adBsAds) {
                      adAmo.adNum++;
                      adYpaBsOp.setAdBlock(adBsAds[index], selTag, adSty);
                      if(adAmo.adNum>=adMax){
                          loopAd = false;
                          break;
                      }
                  }
              }
              if(adAmo.adNum<adMax) loopAd = true;
              var loopAd = adAmo.loopNum >=adAmo.loopMax ? false : loopAd;
              if (loopAd) {
                  kwlist.shift();
                  adYpaBsOp.loadAd(kwlist,selTag,adSty,adMax,adAmo,callback);
              } else if (typeof callback =='function') callback();
          });
      },
      setAdBlock:function(data, selTag, adSty) {
          var adSty=adSty||1;
          var addAd='';
          if (adSty==1) {
              addAd += '<div class="ad-ypapt2-wrap" onclick="window.open(\'' + data['clickURL'] + '\');"><div class="ad-ypapt2-content">';
              addAd += '<div class="ad-ypapt2-head"><span class="ad-ypapt2-link">'+data['sitehost']+'</span><span class="ad-ypapt2-sponsored">sponsored</span></div>';
              addAd += '<p class="ad-ypapt2-title">'+data['title'].replace(/\s?\|.*$/i, '')+'</p><p class="ad-ypapt2-desc">'+data['descr']+'</p><span class="ad-ypapt2-btn">查看詳情</span></div>';
          } else {
              addAd += '<div class="ad-ypapt-wrap" onclick="window.open(\'' + data['clickURL'] + '\');"><div class="ad-ypapt-content">';
              addAd += '<p class="ad-ypapt-head">'+data['sitehost']+'</p><p class="ad-ypapt-title">'+data['title'].replace(/\s?\|.*$/i, '')+'</p><p class="ad-ypapt-desc">'+data['descr']+'</p>';
              addAd += '</div><div class="ad-ypapt-footer"><span class="ad-ypapt-sponsored">sponsored</span><span class="ad-ypapt-btn">查看詳情</span></div></div>';
          }
          $(selTag).append(addAd);
      },
      paddingAd:function(addAdList){
          if (addAdList.length<1) return false;
          var adItem=addAdList.shift();
          adYpaBsOp.loadAd(adItem.kw,adItem.selTag,adItem.adSty,adItem.adMax,{},function(){
            adYpaBsOp.paddingAd(addAdList);
          });
      },
      init:function(){
          var unitKw1 = ["旅遊住宿","自由行", "住院險", "佛教葬儀", "冷氣濾網",
                         "保溼", "保濕化妝品", "保險比較網", "保險試算","spa",
                         "保養商品", "借貸急救網", "plc課程", "兒童保健",
                         "spa按摩機", "創業補習班", "uv光", "台糖桶裝水",
                         "台灣補習班", "外遇法律", "外遇問題", "翻譯日語",
                         "失眠自療法", "失眠治療", "失眠門診", "花藝課", "蜂膠"
                        ];

          var unitKw2 = ["投資理財", "吸塵器", "抗癌食物", "改善過敏",
                         "大貨車考照", "男性保健", "男性陽痿", "失眠障礙",
                         "嬰兒dha", "乳膠床墊", "兒童教學", "兒童學習",
                         "卸妝用品", "推薦保濕", "居家照護", "性功能中醫",
                         "招桃花", "易生菌", "油煙處理", "治療失眠", "有機油",
                         "沖床加工",
                        ];

          var unitKw3 = ["治療重聽", "高血壓", "物流公司", "模具開發",
                         "機械錶", "殯葬禮儀", "空氣除臭", "沖壓模具",
                         "金屬加工", "測試機", "淡斑美白", "消毒機",
                         "日本遊學網", "營養飲品", "無線吸塵器", "長照險",
                         "出軌", "半導體設備", "台北房地產", "眼霜眼袋",
                         "稅務會計", "空氣過濾器", "金屬零件",
                        ]
          // example_1
          var selectedKw = adYpaBsOp.randomSelect([unitKw1, unitKw2, unitKw3])
          // 廣告區塊設定至陣列依序執行, 一個廣告區塊執行結束才會繼續下一個廣告區塊
          var addAdList=[{kw:adYpaBsOp.shuffle($.extend([],selectedKw)),selTag:'.dev-ysm-tag:eq(0)',adSty:1,adMax:1},
                        {kw:adYpaBsOp.shuffle($.extend([],selectedKw)),selTag:'.dev-ysm-tag:eq(1)',adSty:1,adMax:1},
                        {kw:adYpaBsOp.shuffle($.extend([],selectedKw)),selTag:'.dev-ysm-tag:eq(2)',adSty:1,adMax:1},
                        ];
          adYpaBsOp.paddingAd(addAdList);

          // example_2
          // 同時多個廣告區塊各自取得YPA廣告 (同時開啟太多YPA的request會被鎖ip, 會造成廣告不會出來)
          // adYpaBsOp.loadAd(adYpaBsOp.shuffle($.extend([],unitKw)),'.dev-ysm-tag:eq(0)',1, 2);
          // adYpaBsOp.loadAd(adYpaBsOp.shuffle($.extend([],unitKw)),'.dev-ysm-tag:eq(1)',2, 3);
      }
  };window.adYpaBsOp = adYpaBsOp;adYpaBsOp.init();
})();
